// React Required
import React, { Component, lazy, Suspense } from "react";
import ReactDOM from "react-dom";

// Create Import File
import "./index.scss";

// Blocks Layout

import { BrowserRouter, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.min.css";
import * as serviceWorker from "./serviceWorker";
import { FiSettings } from "react-icons/fi";

const DarkPortfolioLanding = lazy(() => import("./dark/PortfolioLanding"));
const error404 = lazy(() => import("./elements/error404"));
const articles = lazy(() => import("./elements/articles/Articles"));
const mfe = lazy(() => import("./elements/articles/list/MFE"));
const reduxToolkit = lazy(() =>
  import("./elements/articles/list/ReduxToolkit")
);
const customHooks = lazy(() => import("./elements/articles/list/CustomHooks"));
const optimizedReact = lazy(() =>
  import("./elements/articles/list/OptimizedReact")
);
const locatorJs = lazy(() => import("./elements/articles/list/LocatorJs"));
const reactLibrary = lazy(() =>
  import("./elements/articles/list/ReactLibrary")
);

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <Suspense
          fallback={
            <div className="col text-center p-5">
              roStudio <FiSettings />
            </div>
          }
        >
          <ToastContainer />
          <Switch>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/`}
              component={DarkPortfolioLanding}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/articles`}
              component={articles}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/mfe-in-react`}
              component={mfe}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/redux-toolkit`}
              component={reduxToolkit}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/custom-hooks`}
              component={customHooks}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/react-performance`}
              component={optimizedReact}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/react-libraries`}
              component={reactLibrary}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/locator-js`}
              component={locatorJs}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/404`}
              component={error404}
            />
            <Route component={error404} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();
